<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >

    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar title="考试详情" left-arrow @click-left="$router.back()">
          <template #right>
            <div @click="toHome()">
              <van-icon name="wap-home-o" size="18" />
              <div style="float: right; margin-left: 5px; font-size: 15px">
                首页
              </div>
            </div>
          </template>
        </van-nav-bar>
      </van-sticky>
      <div class="detail">
        <h2 style="text-align: center">
          {{ answer.examTitle }}
        </h2>
        <van-row style="line-height: 36px">
          <van-col span="8">提交时间：</van-col>
          <van-col span="16">{{ answer.creationTime }}</van-col>
        </van-row>
        <van-row style="line-height: 36px">
          <van-col span="8">考试用时：</van-col>
          <van-col span="16">{{ answer.takeTime }}秒</van-col>
        </van-row>
        <van-row style="line-height: 36px">
          <van-col span="8">总分数：</van-col>
          <van-col span="16">{{ answer.totalScore }}分</van-col>
        </van-row>
        <van-row style="line-height: 36px">
          <van-col span="8">得分：</van-col>
          <van-col span="16">{{ answer.score }}分</van-col>
        </van-row>
        <van-row style="line-height: 36px">
          <van-col span="8">考试用时：</van-col>
          <van-col span="16">{{ answer.takeTime }}秒</van-col>
        </van-row>
        <van-row style="line-height: 36px">
          <van-col span="8">答题结果：</van-col>
        </van-row>

        <van-row>
          <van-col
            span="4"
            v-for="(item, index) in answer.questionAndAnswer"
            :key="item.id.toString()"
          >
            <div style="padding: 5px">
              <van-button
                :color="GetColor(item)"
                @click="GoToQuestion(item)"
                type="info"
                :disabled="showIndex == 0 || submitDisabled"
                block
                >{{ index + 1 }}</van-button
              >
            </div>
          </van-col>
        </van-row>

        <!--详细-->
        <div
          v-for="(item, index) in answer.questionAndAnswer"
          :key="item.id.toString()"
          :id="item.id.toString()"
        >
          <van-cell-group>
            <div style="padding-top: 15px">
              <div class="question">
                <h2>{{ index + 1 }}、{{ item.title }}</h2>

                <div v-if="item.typeId == 1 || item.typeId == 2" class="option">
                  <van-row
                    class="textline"
                    v-for="(option, oindex) in item.questionOptions"
                    :key="option.id.toString()"
                  >
                    <div style="margin-left: 25px">
                      {{ GetOptionTitle(option.title, oindex) }}
                    </div>
                    <van-image
                      v-if="
                        option.imageUrl != undefined &&
                        option.imageUrl.length > 10
                      "
                      :src="option.imageUrl"
                    >
                      <template v-slot:error>图片加载失败</template>
                    </van-image>
                  </van-row>
                </div>

                <div v-if="GetIsCorrect(item) == false" class="textline result">
                  <van-icon name="/image/error.png" size="25" />
                  <div
                    style="
                      display: inline-block;
                      margin-top: -5px;
                      position: absolute;
                    "
                  >
                    回答错误
                  </div>
                </div>
                <div
                  v-if="GetIsCorrect(item) == undefined"
                  class="textline result"
                >
                  <van-icon name="/image/error.png" size="25" />
                  <div
                    style="
                      display: inline-block;
                      margin-top: -5px;
                      position: absolute;
                    "
                  >
                    等待审核
                  </div>
                </div>
                <div v-if="GetIsCorrect(item) == true" class="textline result">
                  <van-icon name="/image/right.png" size="25" />
                  <div
                    style="
                      display: inline-block;
                      margin-top: -5px;
                      position: absolute;
                    "
                  >
                    回答正确
                  </div>
                </div>
                <div v-if="item.suggestedAnswer" class="textline">
                  参考答案：{{ item.suggestedAnswer }}
                </div>
                <div class="textline" v-if="item.typeId <= 2">
                  正确答案：{{ GetCorrectText(item) }}
                </div>
                <div class="textline">您的答案：{{ GetAnswerText(item) }}</div>
                <div v-if="item.answerAnalysis" class="textline">
                  问题解析：{{ item.answerAnalysis }}
                </div>
                <div v-if="item.answerKeyWord" class="textline">
                  答案关键：{{ item.answerKeyWord }}
                </div>
              </div>
            </div>
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { GetAnswer } from "@/api/exam";
export default {
  name: "ExamAnswerDetail",

  data() {
    return {
      loading: true,
      answer: {},
    };
  },

  created() {
    this.getAnswer();
  },

  mounted() {},

  methods: {
    toHome() {
      this.$router.replace({ path: "/" });
    },
    async getAnswer() {
      let para = this.$route.query.para;
      let aresult = await GetAnswer(para); //({ ...this.page })
      this.answer = aresult.data.data;
      this.loading = false;
    },
    GetOptionTitle(title, index) {
      return this.GetABC(index) + "、" + title;
    },
    GetABC(index) {
      var optionIndex = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return optionIndex[index];
    },
    GetColor(questionAndAnswer) {
      var isCorrect = this.GetIsCorrect(questionAndAnswer);

      if (isCorrect == false) {
        return "#ff4b50";
      }
      if (isCorrect == undefined) {
        return "#999999";
      }
      if (isCorrect == true) {
        return "#1dc88c";
      }
    },
    GoToQuestion(questionAndAnswer) {
      document.getElementById(questionAndAnswer.id).scrollIntoView();
    },
    GetIsCorrect(questionAndAnswer) {
      if (questionAndAnswer.typeId != 2) {
        return questionAndAnswer.examAnswerDetail[0].isCorrect;
      } else {
        //多选判断对错
        return (
          this.GetCorrectText(questionAndAnswer) ==
          this.GetAnswerText(questionAndAnswer)
        );
      }
    },
    //正确答案
    GetCorrectText(questionAndAnswer) {
      //选择题
      if (questionAndAnswer.typeId <= 2) {
        var str = "";
        for (
          let index = 0;
          index < questionAndAnswer.questionOptions.length;
          index++
        ) {
          if (questionAndAnswer.questionOptions[index].isAnswer) {
            str += this.GetABC(index);
          }
        }
        return str;
      } else {
        return "";
      }
    },
    //我的答案
    GetAnswerText(questionAndAnswer) {
      //   if (questionAndAnswer.typeId == 1) {
      //     for (
      //       let index = 0;
      //       index < questionAndAnswer.questionOptions.length;
      //       index++
      //     ) {
      //       if (
      //         questionAndAnswer.questionOptions[index].id ==
      //         questionAndAnswer.examAnswerDetail[0].questionOptionId
      //       ) {
      //         return this.GetABC(index);
      //       }
      //     }
      //   }
      var str = "";
      if (questionAndAnswer.typeId <= 2) {
        for (
          let index = 0;
          index < questionAndAnswer.questionOptions.length;
          index++
        ) {
          for (
            let aindex = 0;
            aindex < questionAndAnswer.examAnswerDetail.length;
            aindex++
          ) {
            if (
              questionAndAnswer.questionOptions[index].id ==
              questionAndAnswer.examAnswerDetail[aindex].questionOptionId
            ) {
              str += this.GetABC(index);
            }
          }
        }
      } else {
        str = questionAndAnswer.examAnswerDetail[0].answerText;
      }
      if (str == "") {
        str = "未作答";
      }
      return str;
    },
  },
};
</script>
  
  <style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.loading {
  text-align: center;
}
.detail {
  padding: 10px 10px 20px;
}
.textline {
  line-height: 36px;
}
.result {
  padding: 20px 0px 0px 0px;
}
</style>
  