import request from "@/utils/request";
//提交考试内容
export const AddAnswer = (obj) => {
  return request.post("/api/exam/AddAnswer", obj);
};
//考试试题信息
// export const GetExamQuestionForUser = (id) => {
//     return request.get({url: `/api/exam/GetExamQuestionForUser?id=`+id});
// };  

export function GetExamQuestionForUser(id) {
  return request({
    url: `/api/exam/GetExamQuestionForUser?id=${id}`
  })
}

//用户参加考试列表
export const ExamAnswerList = (obj) => {
  return request.post("/api/exam/ExamAnswerList", obj);
};

//用户某次考试答案解析
export function GetAnswer(id) {
  return request({
    url: `/api/exam/GetAnswer?para=${id}`
  })
}  